import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { Router, RouterModule, Routes } from '@angular/router';
import { EffectsRootModule } from '@ngrx/effects';
import { StoreRootModule } from '@ngrx/store';
import {
  ComponentStateModule,
  ContextMenuAction,
  MicrosoftAuthenticationGuard,
  SideBarAction,
  TopLayoutComponent,
} from 'processdelight-angular-components';
import { filter, first, map, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core.module';
import {
  navActions$,
  varlicense$,
  vartranslations$,
} from './services/startup.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    TopLayoutComponent,
    CoreModule,
    EffectsRootModule,
    StoreRootModule,
    MatLuxonDateModule,
    ComponentStateModule,
    MatSnackBarModule,
  ],
})
export class AppComponent implements OnDestroy, OnInit {
  public static domainName = 'Ishtar.List';
  public static appName = 'IshtarList';
  appName = AppComponent.domainName.replace('.', '');
  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));
  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');
  userName$ = varlicense$.pipe(map((u) => u?.name));
  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));

  destroy$ = new Subject<void>();

  iconActions: ContextMenuAction<unknown>[] = [];
  sideBarActions: SideBarAction[] = [];
  navActions = navActions$;

  public constructor(private router: Router) {}

  public ngOnInit(): void {
    vartranslations$
      .pipe(
        filter((t) => Object.keys(t).length > 0),
        first()
      )
      .subscribe((t) => {
        varlicense$
          .pipe(
            filter((l) => !!l && !!Object.keys(l).length),
            first()
          )
          .subscribe((l) => {
            if (
              l?.licenses.some(
                (x) => x.productName === AppComponent.domainName && x.isAdmin
              )
            ) {
              this.iconActions.push(
                new ContextMenuAction<unknown>({
                  label: vartranslations$
                    .asObservable()
                    .pipe(map((t) => t.settings)),
                  icon: 'settings',
                  iconOutline: true,
                  action: () => this.router.navigate(['settings', 'templates']),
                })
              );
            }
          });
        this.sideBarActions.push(
          new SideBarAction({
            title: t?.clients,
            icon: 'app_registration',
            iconOutline: true,
            route: ['clients'],
          })
        );
      });

    const params = new URLSearchParams(location.search);
    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );
    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (tabIconLink) tabIconLink.href = this.appLogo;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: 'clients',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./client/client-dashboard/client-dashboard.component').then(
        (mod) => mod.ClientDashboardComponent
      ),
    data: { breadcrumbTitle: vartranslations$.pipe(map((u) => u?.clients)) },
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./admin/components/settings/settings.component'),
    loadChildren: () =>
      import('./admin/components/settings/settings.component').then(
        (settings) => settings.settingsRoutes
      ),
    canActivate: [
      MicrosoftAuthenticationGuard,
      () => {
        const router = inject(Router);
        return varlicense$.pipe(
          map((l) =>
            l?.licenses.some(
              (x) => x.productName == AppComponent.domainName && x.isAdmin
            )
              ? true
              : router.createUrlTree(['page'])
          )
        );
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
