import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { dateTimesToISO, IMetadataService, Metadata, MetadataChoice, MetadataChoiceTranslation, MetadataParam,
  MetadataPermission, MetadataPermissionConfiguration } from 'processdelight-angular-components';
import { map, Observable } from 'rxjs';

@Injectable()
export class MetadataService implements IMetadataService {
  private readonly apiBase = `${location.origin}/web`;
  private readonly httpClient = inject(HttpClient);

  public getMetadataParams(getAll: boolean): Observable<MetadataParam[]> {
    return this.httpClient.get<MetadataParam[]>(
        `${this.apiBase}/metadata/parameters?getAll=${getAll}`)
      .pipe(map((params) => params.map((p) => new MetadataParam(p))));
  }

  checkExistingMetadata(
    metadata: Metadata[],
    fileExtension: string,
    excludedId?: string,
    libraryId?: string): Observable<string | undefined> {
    return this.httpClient.post<string | undefined>(
      `${this.apiBase}/metadata/checkExisting${
        libraryId ? `?libraryId=${libraryId}` : ''
      }`,
      {
        metadata: dateTimesToISO(metadata),
        fileExtension,
        excludedId,
      },
      { responseType: 'text' as 'json' }
    );
  }

  createMetadataChoice(choice: MetadataChoice): Observable<MetadataChoice> {
    throw new Error('Method not implemented.');
  }

  createMetadataParam(metadataParam: MetadataParam): Observable<MetadataParam> {
    throw new Error('Method not implemented.');
  }
  updateMetadataParam(metadataParam: MetadataParam): Observable<MetadataParam> {
    throw new Error('Method not implemented.');
  }
  deleteMetadataParam(metadataParamId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getMetadataPermissionConfigurations(): Observable<
    MetadataPermissionConfiguration[]
  > {
    throw new Error('Method not implemented.');
  }
  updateMetadataPermissions(
    permissions: MetadataPermission[]
  ): Observable<MetadataPermission[]> {
    throw new Error('Method not implemented.');
  }
  updateMetadataPermissionConfiguration(
    permissionConfig: MetadataPermissionConfiguration
  ): Observable<MetadataPermissionConfiguration> {
    throw new Error('Method not implemented.');
  }
  updateMetadataChoice(choice: MetadataChoice): Observable<MetadataChoice> {
    throw new Error('Method not implemented.');
  }
  updateMetadataChoices(
    choices: MetadataChoice[]
  ): Observable<MetadataChoice[]> {
    throw new Error('Method not implemented.');
  }
  deleteMetadataChoice(paramId: string, choiceId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  createMetadataChoiceTranslations(
    translations: MetadataChoiceTranslation[]
  ): Observable<MetadataChoiceTranslation[]> {
    throw new Error('Method not implemented.');
  }
}
