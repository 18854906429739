import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import {
  FunctionsService,
  ILibraryService,
  IUploadResult,
  Library,
  LibraryTrigger,
  Permission,
  LibraryItem,
  dateTimesToISO
} from 'processdelight-angular-components';
import { catchError, map, Observable, of, tap } from 'rxjs';

@Injectable()
export class LibraryService implements ILibraryService {
    private readonly apiBase = `${location.origin}/web`;
    private readonly httpClient = inject(HttpClient);
    private readonly functionsService = inject(FunctionsService);

  public getLibraries(getAll: boolean): Observable<Library[]> {
    return this.httpClient
      .get<Library[]>(`${this.apiBase}/libraries?getAll=${getAll}`)
      .pipe(map((m) => m.map((p) => new Library(camelcaseKeys(p)))));
  }

  public uploadLibraryFile(fileName: string, blob: Blob, relativeUrl?: string): Observable<IUploadResult> {
      const formData = new FormData();
      formData.append('file', blob, fileName);
      formData.append('relativeUrl', relativeUrl ?? 'IshtarDMSLibraryItems');
      
      return this.httpClient.post<IUploadResult>(
          `${this.apiBase}/libraries/upload`, formData)
        .pipe(tap((result) => result.fileLocation = this.functionsService
          .getFileLocation(result.relativeUrl)));
  }

  public uploadFileChunk(
      fileName: string,
      relativeUrl: string | undefined,
      blob: Blob,
      start?: number,
      end?: number,
      uploadId?: string
  ): Observable<IUploadResult> {
      const formData = new FormData();
      formData.append('file', blob, fileName);
      formData.append('relativeUrl', relativeUrl ?? 'IshtarDMSLibraryItems');
      formData.append('start', start?.toString() ?? '0');
      formData.append('end', end?.toString() ?? blob.size.toString());
      if (uploadId) formData.append('uploadId', uploadId);
      
      return this.httpClient.post<IUploadResult>(
          `${this.apiBase}/libraries/uploadChunk`, formData)
        .pipe(tap((result) => result.fileLocation = this.functionsService
          .getFileLocation(result.relativeUrl)));
  }

  public checkFileName(libraryName: string, fileName: string): Observable<boolean> {
      return this.httpClient.post<boolean>(
          `${this.apiBase}/libraries/checkFileName('${fileName}')?relativeUrl=${libraryName}`,
          {}
      );
  }

  public checkUploadedFiles(fileName: string, libraryId?: string): Observable<LibraryItem | undefined> {
      return this.httpClient.post<LibraryItem | undefined>(
        `${this.apiBase}/libraries/checkUploaded('${fileName}')?${
          libraryId ? `libraryId=${libraryId}` : ''
        }`,
        {})
      .pipe(
        map((m) => (m ? new LibraryItem(camelcaseKeys(m)) : undefined)),
        catchError(() => of(undefined)));
  }

  public createLibraryItems(items: LibraryItem[]): Observable<LibraryItem[]> {
    return this.httpClient.post<LibraryItem[]>(
      `${this.apiBase}/documents`, dateTimesToISO(items))
    .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
  }

  public updateLibrary(library: Library): Observable<Library> {
      throw new Error("Method not implemented.");
  }

  createLibrary(library: Library): Observable<Library> {
    throw new Error('Method not implemented.');
  }
  deleteLibrary(libraryId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getLibraryItems(
    orderBy?: string,
    orderByDirection?: 'asc' | 'desc',
    filters?: { [key: string]: string },
    pageSize?: number,
    page?: number,
    libraryId?: string
  ): Observable<{ result: LibraryItem[]; totalRecordCount: number }> {
    throw new Error('Method not implemented.');
  }
  updateLibraryItemsForMove(items: LibraryItem[]): Observable<LibraryItem[]> {
    throw new Error('Method not implemented.');
  }
  updateLibraryItems(items: LibraryItem[]): Observable<LibraryItem[]> {
    throw new Error('Method not implemented.');
  }
  deleteLibraryItems(itemIds: string[]): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  createLibraryPermission(
    permission: Permission,
    libraryId: string
  ): Observable<Permission> {
    throw new Error('Method not implemented.');
  }
  updateLibraryPermissions(
    permissions: Permission[]
  ): Observable<Permission[]> {
    throw new Error('Method not implemented.');
  }
  deleteLibraryPermissions(permissionIds: string[]): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getLibraryTriggers(): Observable<LibraryTrigger[]> {
    throw new Error('Method not implemented.');
  }
  getSharedItems(
    orderBy?: string,
    orderByDirection?: 'asc' | 'desc',
    filters?: { [key: string]: string },
    pageSize?: number,
    page?: number
  ): Observable<{ result: LibraryItem[]; totalRecordCount: number }> {
    throw new Error('Method not implemented.');
  }
}
